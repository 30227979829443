import {Component, OnInit} from '@angular/core'
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap'
import {ToastrService} from 'ngx-toastr'
import {ProductRepositoryService} from '../../clothing.repository.service'

import {MediaService} from '../../../../../core/modules/sys-shared/services/media/media.service'
import {Router} from "@angular/router";
import * as moment from "moment-timezone";
import {SwalService} from "../../../../../core/modules/sys-shared/services/swal.service";

@Component({
    selector: 'app-product-modal',
    templateUrl: './search-sale-by-product-modal.component.html',
    styleUrls: ['./search-sale-by-product-modal.component.css']
})
export class SearchSaleByProductModalComponent implements OnInit {

    filterPost: string = ''
    searching: boolean = false

    constructor(private modal: NgbActiveModal,
                private productRepositoryService: ProductRepositoryService,
                private router: Router) {
    }

    async ngOnInit() {

    }

    async getSaleByProduct() {
        this.searching = true
        const sale = await this.productRepositoryService.getSaleByArticleBarcode(this.filterPost)
        console.log(sale)
        if (!sale) {
            SwalService.error('Error', 'No se pudo encontrar la venta')
            this.searching = false
            return
        }
        const date = moment(sale.dateCreateAt, 'YYYY-MM-DDTHH:mm:ssZZ').tz('America/Lima').format('YYYY-MM-DD')
        this.router.navigate(['/sales/add-sale'], {
            queryParams: {
                idSale: sale.idSale,
                idCash: sale.idCash,
                date: date
            }
        })
        this.searching = false
        this.closeModal()
    }

    closeModal(result: any = null) {
        this.modal.close({
            // idWarehouse: this.idWarehouseFC.value,
            // idSegment: this.idSegment.value,
            // idProductCategory: this.idProductCategory.value
        })
    }
}

<div class="modal-header align-items-center"
     style="height: 50px">
    <div class="float-left">
        <h4 class="modal-title text-mcs">Buscar venta por producto</h4>
    </div>
    <div class="float-right">
        <ul class="header-dropdown">
            <a role="button" style="opacity: 0.7" (click)="closeModal('click-close')" title="Cerrar Modal">
                <i class="fa fa-close fa-lg text-danger"></i>
            </a>
        </ul>
    </div>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="d-flex flex-wrap align-items-center mb-1">
                <div class="input-search">
                    <div class="input-group m-b-0">
                        <div class="input-group-prepend">
                            <div class="input-group-text"><i class="icon-magnifier"></i></div>
                        </div>
                        <input #inputValue style="width: 400px"
                               type="text"
                               placeholder="Buscar por código de producto"
                               (keyup)="filterPost = inputValue.value"
                               class="form-control form-control-sm">
                    </div>
                </div>
                <button class="btn btn-sm btn-outline-info"
                        type="button"
                        (click)="getSaleByProduct()"
                        [disabled]="searching"
                        title="Buscar producto">
                    <i class="fa fa-search mr-1"></i> Buscar
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="row">
        <button class="btn btn-sm btn-outline-danger mr-1"
                (click)="closeModal()"><i class="fa fa-times-circle mr-2" aria-hidden="true"></i> Cancelar
        </button>
    </div>
</div>

